
form {
    margin: 5vw auto 0;
}

div {
    margin-bottom: 10px;
}

label.input-title {
    display: inline-block;
    width: 120px;
    /* Adjust the width as needed */
    text-align: left;
    margin-right: 20px;
}

input[type="number"] {
    width: calc(100% - 130px);
    /* Adjust the width as needed */
    max-width: 60px;
    text-align: right;
}
input[type="radio"] {
    margin-left: 10px
}



button {
    display: block;
    margin: 10px auto;
    /* Adjust the margin as needed */
    width: 150px;
    /* Adjust the width as needed */
    height: 40px;
    /* Adjust the height as needed */
    background-color: rgb(0, 86, 216);
    /* Adjust the color as needed */
    color: white;
    /* Adjust the text color as needed */
    border: none;
    /* Remove the border */
    border-radius: 8px;
    /* Adjust the border radius as needed */
    font-size: 16px;
    /* Adjust the font size as needed */
}